import React, { useRef, useContext, useState } from "react";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

import Templete from "../Templete/Templete";

import AuthService from "../../../services/User/AuthService";
import ProfileService from "../../../services/User/ProfileService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";

const CommissionSummary = () => {
  const loadingCtx = useContext(LoadingContext);
  const history = useHistory();

  const downloadHandler = async () => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await ProfileService.downloadCommissionSummary(
        token,
        selectedDate
      );

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        const link = responseData.data.link;
        window.open(link, "_blank", "noopener,noreferrer");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  const [selectedDate, setSelectedDate] = useState(null); // Store only month & year

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Commission Summary">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">Commission Summary</h3>
                  </div>

                  <div class="row pt-2 pb-2">
                    <div class="col-md-6 mx-auto">
                      <div class="card-body">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            views={["year", "month"]}
                            label="Select Month & Year"
                            value={selectedDate ? dayjs(selectedDate) : null} // Show nothing initially
                            onChange={(newValue) => {
                              if (newValue) {
                                setSelectedDate(newValue.format("YYYY-MM")); // Store as "YYYY-MM"
                              }
                            }}
                            format="MM/YYYY"
                            maxDate={dayjs()} // Restrict future dates
                            slotProps={{
                              textField: {
                                variant: "outlined",
                                fullWidth: true,
                              },
                            }}
                          />
                        </LocalizationProvider>

                        <div class="text-center pt-3">
                          <button
                            type="button"
                            class="btn btn-primary"
                            onClick={downloadHandler}
                          >
                            <i className="fa fa-download"></i> Download
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>
    </React.Fragment>
  );
};

export default CommissionSummary;
